// ContactForm.js
import React, { useState } from 'react';
import './ContactForm.css'; // Make sure to create this CSS file for styling

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '', 
        email: '',
        subject: '',
        message: ''
    });

    const [isSent, setIsSent] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('/Home/SubmitContactForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setIsSent(true);
                alert('Your message has been sent!');
                setFormData({ firstName: '', lastName: '', email: '', subject: '', message: '' }); // Reset form
            } else {
                alert('Failed to send message. Please try again later.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again later.');
        }
    };

    return (
        <div className="contactFormCard">
            <form className="contactForm" onSubmit={handleSubmit}>
                <div className="formRow"> {/* Add this wrapper */}
                    <input
                        name="name"
                        type="text"
                        placeholder="Ditt navn"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <input
                        name="email"
                        type="email"
                        placeholder="Epost-adresse"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <input
                    name="subject"
                    type="text"
                    placeholder="Emne"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="message"
                    placeholder="Din melding"
                    value={formData.message}
                    onChange={handleChange}
                    required
                ></textarea>
                <button disabled={isSent} type="submit">Send inn</button>
            </form>
        </div>
    );
};

export default ContactForm;
